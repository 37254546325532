import { connect } from 'react-redux'

import {
  doMarkAsRead,
  doFetchTicketActions,
  doFetchTicketLinkedResources,
} from 'actions/tickets'

import { selectIsCustomerEmailBlank } from 'selectors/tickets'
import { selectCurrentTicketUndecorated } from 'selectors/tickets/current/selectCurrentTicketUndecorated'

import { selectCurrentTicketChangesetsLoaded } from 'selectors/currentChangesets'

import {
  selectIsEditorPinned,
  selectIsEditorVisible,
} from 'ducks/editor/selectors'

import TicketInspector from './view'

const select = state => ({
  isCustomerEmailBlank: selectIsCustomerEmailBlank(state),
  // perf: this is pants. But better than passing down thru Layout!!
  ticket: selectCurrentTicketUndecorated(state),
  changesetsLoaded: selectCurrentTicketChangesetsLoaded(state),
  isEditorVisible: selectIsEditorVisible(state),
  isEditorPinned: selectIsEditorPinned(state),
})

const perform = {
  doMarkAsRead,
  doFetchTicketActions,
  doFetchTicketLinkedResources,
}

export default connect(select, perform)(TicketInspector)
