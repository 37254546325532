import React, { useState, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { doInitializeRequest } from 'ducks/ai/operations'
import { record } from 'ducks/tracking/actions'
import colorfulStars from 'assets/colorful-stars.png'
import {
  threeStars as ThreeStars,
  designTool as DesignTool,
  discoBall as DiscoBall,
  expand as Expand,
  shortenHorizontal as ShortenHorizontal,
  toolBox as ToolBox,
  wrench as Wrench,
} from 'assets/icons/groove/v2'
import {
  SPELLING_ACTION,
  SHORTEN_ACTION,
  LENGTHEN_ACTION,
  FORMAL_ACTION,
  CASUAL_ACTION,
  CLARIFY_ACTION,
  REQUEST_TYPES,
} from 'ducks/ai/types'

import { styles } from './styles'
import Menu from './Menu'

export const aiWritingTools = [
  {
    id: SPELLING_ACTION,
    name: 'Fix spelling & grammar',
    icon: Wrench,
  },
  {
    id: SHORTEN_ACTION,
    name: 'Make shorter',
    icon: ShortenHorizontal,
  },
  {
    id: LENGTHEN_ACTION,
    name: 'Make longer',
    icon: Expand,
  },
  {
    id: FORMAL_ACTION,
    name: `Make more formal`,
    icon: ToolBox,
  },
  {
    id: CASUAL_ACTION,
    name: `Make more casual`,
    icon: DiscoBall,
  },
  {
    id: CLARIFY_ACTION,
    name: 'Simplify language',
    icon: DesignTool,
  },
]

const menuList = {
  heading: {
    name: 'AI EDIT',
    icon: ThreeStars,
  },
  items: aiWritingTools,
}

const AiButton = ({ className }) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const dispatch = useDispatch()

  const handleSelect = useCallback(
    id => {
      record()
      dispatch(doInitializeRequest(REQUEST_TYPES[id], id))
      setMenuVisible(false)
    },
    [dispatch]
  )

  const preventTriggerEnter = useCallback(e => {
    // Enable handleSelect with enter key
    e.stopPropagation()
  }, [])

  const overlay = useMemo(
    () => {
      const HeaderIcon = menuList.heading.icon
      return (
        <>
          <div css={styles.heading}>
            <HeaderIcon />
            <span>{menuList.heading.name}</span>
          </div>
          <Menu>
            {menuList.items.map(item => {
              const Icon = item.icon
              return (
                <Menu.Item
                  key={item.id}
                  itemKey={item.id}
                  onSelect={handleSelect}
                >
                  <Icon />
                  <span>{item.name}</span>
                </Menu.Item>
              )
            })}
          </Menu>
        </>
      )
    },
    [handleSelect]
  )

  return (
    <Dropdown
      overlay={overlay}
      visible={menuVisible}
      onVisibleChange={setMenuVisible}
      portal="#overlays"
      strategy="fixed"
      position="top-start"
      autoHeight
      isNavByArrowsDisabled
      css={styles.dropdown}
      className={className}
      offset={4}
    >
      <div className="grui mr-5">
        <Tooltip
          title="AI Edit"
          strategy="fixed"
          position="top"
          disabled={menuVisible}
        >
          <button
            css={[button.styles.preflight, styles.btn]}
            onKeyDown={preventTriggerEnter}
            onKeyUp={preventTriggerEnter}
          >
            <img src={colorfulStars} alt="AI Edit" width="32" height="32" />
          </button>
        </Tooltip>
      </div>
    </Dropdown>
  )
}

export default AiButton
