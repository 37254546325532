import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TO_SETTINGS_BILLING_PAGE } from 'ducks/billing/constants'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import { selectSuggestionForType } from 'ducks/ai/selectors/suggestions'
import { doUpsertAiConversationSuggestions } from 'ducks/ai/operations'
import { FEATURE_AI_OVERVIEW_TIER } from 'ducks/billing/featureTypes'
import { selectCurrentInbox } from 'ducks/billing/selectors/selectCurrentInbox'
import { useFeature } from 'ducks/billing/hooks'
import {
  selectAiOverviewAccountWallet,
  selectAiOverviewUserWallet,
} from 'ducks/wallets/selectors'
import { ADMIN_EMAIL_NOTIFICATION_TYPES } from 'ducks/ai/types'
import { doAgentSendAdminEmailNotification } from 'ducks/agents/operations'
import { isFeatureEnabled } from 'ducks/tracking/actions'
import EmptyOrOverview from './EmptyOrOverview'
import UsageCounter from '../UsageCounter'

const billingFeatureKey = FEATURE_AI_OVERVIEW_TIER
const billingFeatureAdvancedTier = 2

const allSuggestionTypes = ['sentiment', 'summary', 'tags']

const Content = ({ ticketId, isLoaded, isLoading }) => {
  const dispatch = useDispatch()
  const [isAccessRequestBtnDisabled, setIsAccessRequestBtnDisabled] = useState(
    false
  )
  const userWallet = useSelector(selectAiOverviewUserWallet)
  const accountWallet = useSelector(selectAiOverviewAccountWallet)
  const isAiOverviewReady = isFeatureEnabled('ai-overview-ready')
  const { canUseFeature: canUseFeatureByBilling, featureLimit } = useFeature(
    billingFeatureKey
  )
  const canUseFeature = isAiOverviewReady && canUseFeatureByBilling
  const canUseUnlimitedAIOverviews =
    canUseFeature && featureLimit === billingFeatureAdvancedTier
  const limitReached =
    userWallet.id &&
    !userWallet.canUse &&
    !canUseUnlimitedAIOverviews &&
    isAiOverviewReady
  const isCurrentUserManager = useSelector(selectCurrentUserIsAdminOrOwner)
  const currentInboxPlan = useSelector(selectCurrentInbox)
  const currentPlanName = currentInboxPlan.pricing?.name
  const isAutoGenerationEnabled = accountWallet.canUse && isAiOverviewReady

  const sentimentSuggestion = useSelector(state =>
    selectSuggestionForType(state, ticketId, { type: 'sentiment' })
  )
  const summarySuggestion = useSelector(state =>
    selectSuggestionForType(state, ticketId, { type: 'summary' })
  )
  const tagsSuggestion = useSelector(state =>
    selectSuggestionForType(state, ticketId, { type: 'tags' })
  )

  const suggestionTypes = useMemo(
    () => {
      return canUseFeature ? allSuggestionTypes : ['summary']
    },
    [canUseFeature]
  )

  const suggestionSelectors = {
    sentiment: sentimentSuggestion,
    summary: summarySuggestion,
    tags: tagsSuggestion,
  }

  const isAnySuggestionNotPresentAndNotLoading = suggestionTypes
    .map(t => suggestionSelectors[t])
    .some(suggestion => {
      return !suggestion.suggestion?.id && !suggestion.isLoading
    })

  const isSuggestionTypeLoading = suggestionTypes
    .map(t => suggestionSelectors[t])
    .some(suggestion => suggestion.isLoading)

  const canShowLoadingState = isLoading !== false || isSuggestionTypeLoading

  const canShowEmptyState =
    isAnySuggestionNotPresentAndNotLoading && !canShowLoadingState

  const isSuggestionsLoaded = suggestionTypes
    .map(t => suggestionSelectors[t])
    .every(suggestion => suggestion.suggestion?.id)

  const canShowUsageCounter =
    userWallet.id &&
    !canUseUnlimitedAIOverviews &&
    !canShowLoadingState &&
    isAiOverviewReady

  const generateFullOverview = useCallback(
    ({ isAutoGenerated, skipLoaded } = {}) => {
      dispatch(
        doUpsertAiConversationSuggestions({
          suggestionTypes,
          ticketId,
          isAutoGenerated,
          skipLoaded,
        })
      )
    },
    [dispatch, ticketId, suggestionTypes]
  )

  const handleGenerateFullOverviewClickSkipLoaded = useCallback(
    () => {
      generateFullOverview({ skipLoaded: true })
    },
    [generateFullOverview]
  )

  const handleRequestUpgradeOrGoToBilling = useCallback(
    async () => {
      if (isCurrentUserManager) {
        dispatch(TO_SETTINGS_BILLING_PAGE)
      } else {
        setIsAccessRequestBtnDisabled(true)
        await dispatch(
          doAgentSendAdminEmailNotification(
            ADMIN_EMAIL_NOTIFICATION_TYPES.aiOverview
          )
        )
        setIsAccessRequestBtnDisabled(false)
      }
    },
    [dispatch, isCurrentUserManager]
  )

  useEffect(
    () => {
      if (
        !isAutoGenerationEnabled ||
        isSuggestionTypeLoading ||
        isSuggestionsLoaded ||
        !isLoaded
      ) {
        return
      }
      // Auto generate unexisted suggestions
      generateFullOverview({ isAutoGenerated: true, skipLoaded: true })
    },
    [
      dispatch,
      isSuggestionTypeLoading,
      isSuggestionsLoaded,
      ticketId,
      generateFullOverview,
      isLoaded,
      isAutoGenerationEnabled,
    ]
  )

  return (
    <>
      {canShowUsageCounter && (
        <UsageCounter
          total={userWallet.limit}
          used={userWallet.limit - userWallet.balance}
          remaining={userWallet.balance > 0 ? userWallet.balance : 0}
          onUpgradeAction={handleRequestUpgradeOrGoToBilling}
          isAccessRequestBtnDisabled={isAccessRequestBtnDisabled}
          isCurrentUserManager={isCurrentUserManager}
          limitReached={limitReached}
          canShowUpgradeAction={canShowEmptyState ? !limitReached : true}
          currentPlanName={currentPlanName}
        />
      )}
      <div className="grui p-10">
        <EmptyOrOverview
          ticketId={ticketId}
          limitReached={limitReached}
          canShowEmptyState={canShowEmptyState}
          onUpgradeAction={handleRequestUpgradeOrGoToBilling}
          isAccessRequestBtnDisabled={isAccessRequestBtnDisabled}
          isCurrentUserManager={isCurrentUserManager}
          onGenerateFullOverviewClickSkipLoaded={
            handleGenerateFullOverviewClickSkipLoaded
          }
          onGenerateFullOverview={generateFullOverview}
          canShowLoadingState={canShowLoadingState}
          sentimentSuggestion={sentimentSuggestion}
          summarySuggestion={summarySuggestion}
          tagsSuggestion={tagsSuggestion}
          currentPlanName={currentPlanName}
          canUseClassifications={canUseFeature}
        />
      </div>
    </>
  )
}

export default memo(Content)
