import { connect } from 'react-redux'

import {
  selectCustomFieldValueContentForCustomFieldKeyAndCategoryType,
  selectCurrentContactName,
  selectCurrentContact,
} from 'ducks/crm/contacts'

import ShopifyIndexView from './view'

const select = (state, { integration }) => {
  const { storeDomain } = integration
  const categoryType = 'CONTACT'
  const keyPrefix = `shopify_${storeDomain}.myshopify.com_`
  const handle = selectCustomFieldValueContentForCustomFieldKeyAndCategoryType(
    state,
    {
      customFieldKey: `${keyPrefix}customer_id`,
      categoryType,
    }
  )

  const fields = {}
  const fieldKeys = [
    ['addresses', 'addresses'],
    ['customerId', 'customer_id'],
    ['lifetimeDuration', 'lifetime_duration'],
    ['locale', 'locale'],
    ['marketingOptInLevel', 'marketing_opt_in_level'],
    ['ordersCount', 'orders_count'],
    ['productSubscriberStatus', 'product_subscriber_status'],
    ['state', 'state'],
    ['tags', 'tags'],
    ['taxExempt', 'tax_exempt'],
    ['taxExemptions', 'taxExemptions'],
    ['totalSpent', 'total_spent'],
    ['lastOrderId', 'last_order_id'],
    ['note', 'note'],
    ['currency', 'currency'],
  ]
  fieldKeys.forEach(field => {
    const value = selectCustomFieldValueContentForCustomFieldKeyAndCategoryType(
      state,
      {
        customFieldKey: `${keyPrefix}${field[1]}`,
        categoryType,
      }
    )
    fields[field[0]] = value
  })

  const customerGid = handle ? `gid://shopify/Customer/${handle}` : null
  return {
    ...fields,
    contact: selectCurrentContact(state),
    handle,
    name: selectCurrentContactName(state),
    integration,
    customerGid,
  }
}

export default connect(select, {})(ShopifyIndexView)
