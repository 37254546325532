import React, { useCallback, useContext, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import cn from 'classnames'

import { FocusContext } from '../Expanded/FocusContext'

const inputStyle = theme => css`
  &&& {
    background-color: inherit;
    border: 0;
    color: inherit;
    flex-grow: 1;
    font-size: 13px;
    font: inherit;
    line-height: 1.38 !important;
    min-width: 200px;
    outline: 0;
    padding: 0;
    width: 100%;

    ::placeholder {
      color: ${theme.color.monochrome.mediumDark};
    }
  }
`

const Input = ({ className, value, type, placeholder, tabIndex, onChange }) => {
  const { getFocusableRef } = useContext(FocusContext)

  const [internalValue, setInternalValue] = useState(value)

  useEffect(
    () => {
      setInternalValue(currentInternalValue => {
        if (value !== currentInternalValue) {
          return value
        }
        return currentInternalValue
      })
    },
    [value]
  )

  const handleChange = useCallback(
    e => {
      setInternalValue(e.currentTarget.value)
      onChange(e.currentTarget.value)
    },
    [onChange]
  )

  return (
    <input
      css={inputStyle}
      className={cn(className, 'grui truncate')}
      id={type}
      value={internalValue}
      onChange={handleChange}
      placeholder={placeholder}
      ref={getFocusableRef(type)}
      tabIndex={tabIndex}
      maxLength="191"
    />
  )
}

export default Input
