import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { selectIsLoggingNewConversation } from 'selectors/page'

import RecipientsEditor from './RecipientsEditor'
import Label from './Label'

const ToRow = ({
  children, // e.g. FormSwitcherButton
  className,
  editing,
  ticketId,
  onUpdate,
  isForwarding,
  isNote,
  ...rest
}) => {
  const isLogging = useSelector(selectIsLoggingNewConversation)
  return (
    <div className={cn(className, 'grui', 'flex', ' flex-grow')}>
      {children}
      <div
        className={cn(
          'toEditor grui flex',
          { 'grui ml-4': !!children },
          { 'grui ml-0': !children }
        )}
        css={css`
          min-width: 0;
          flex: 1 1 60%;
          min-height: 26px;
        `}
      >
        <Label>{isLogging ? 'Customer' : 'To'}:</Label>
        <RecipientsEditor
          key={`recipientseditor-to-${ticketId}`}
          className="grui ml-3"
          type="to"
          ticketId={ticketId}
          allowMultipleRecipients={isForwarding}
          draftType={isNote ? 'note' : 'reply'}
          {...rest}
        />
      </div>
    </div>
  )
}

ToRow.propTypes = {
  recipientsOpenUpward: PropTypes.bool,
}

ToRow.defaultProps = {
  recipientsOpenUpward: false,
}

export default styled(ToRow)`
  .label {
    line-height: 26px; /* hacks around jumpy label as you remove recipient */
  }

  .actions {
    line-height: 2;
  }
`
