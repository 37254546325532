import React, { forwardRef } from 'react'
import { Picker } from 'emoji-mart'
import { Flex, SUI } from 'shared/ui'
import { isMac, isWindows } from 'util/browser'
import './styles.less'

const selectAdjustment = onSelect => ({ native }) => onSelect(native)

let useMac = null
const EmojiPicker = forwardRef(({ onSelect, theme }, ref) => {
  if (useMac === null) {
    useMac = isMac()
  }

  return (
    <SUI>
      <Flex className="grui flex-col">
        <Picker
          ref={ref}
          title="Pick your emoji..."
          autoFocus
          set={useMac ? 'apple' : 'google'}
          onSelect={selectAdjustment(onSelect)}
          showSkinTones
          showPreview
          color={theme.color.monochrome.black}
          i18n={{ search: 'Search...' }}
          perLine={7}
          emojiSize={28}
          emoji="point_up"
          useButton={false}
          // eslint-disable-next-line react/jsx-no-bind
          emojisToShowFilter={emoji => {
            /** (pnagy) In Windows the emojis rendered a bit differently and they totally mess up the view
             * The issue was reported to them and once it's resolved we can remove this filter. Until then we
             * exclude the gender differentiated emojis in Edge
             * https://github.com/missive/emoji-mart/issues/350
             */
            if (!isWindows()) return true
            if (emoji.name === 'Waving White Flag') return true
            if (emoji.name === 'Waving Black Flag') return true
            if (emoji.name === 'Rainbow Flag') return true
            if (emoji.name === 'Triangular Flag on Post') return true
            const isFlagEmoji = emoji.search.indexOf('flag') > -1
            const isDoctorSignInTheString = emoji.unified.indexOf('2695') > -1
            const isLawSignInTheString = emoji.unified.indexOf('2696') > -1
            const isMaleSignInTheString = emoji.unified.indexOf('2642') > -1
            const isFemaleSignInTheString = emoji.unified.indexOf('2640') > -1
            return (
              !isFlagEmoji &&
              !isMaleSignInTheString &&
              !isFemaleSignInTheString &&
              !isDoctorSignInTheString &&
              !isLawSignInTheString
            )
          }}
        />
      </Flex>
    </SUI>
  )
})

export default EmojiPicker
