import React, { useCallback, useMemo } from 'react'
import {
  CHANNEL_EMAIL_TYPES,
  SECONDARY_EMAIL_CHANNEL,
} from 'subapps/settings/components/drawers/Channels/Channels.data'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_SELECT_EMAIL } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import ChannelOption from './ChannelOption'
import { styles } from './styles'

const Footer = ({ className, firstStep = false, onClickCreateBtn }) => {
  const { drawerId: emailDrawerId, openDrawer: openEmailDrawer } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_SELECT_EMAIL,
    lazyLoad: true,
    closeIgnoresStack: true,
  })

  const channelList = useMemo(() => {
    return Object.keys(CHANNEL_EMAIL_TYPES)
      .map(type => {
        return {
          channelType: type,
          ...CHANNEL_EMAIL_TYPES[type],
        }
      })
      .filter(channel => !channel.isSecondaryType)
  }, [])

  const handleOpenSecondaryEmailChannels = useCallback(
    () => {
      openEmailDrawer('new', {
        query: {
          ...buildDrawerQueryParam(emailDrawerId, 'drawerIsSecondary', 1),
        },
        additionalProps: {
          drawerSource: 'inbox',
        },
      })
      if (onClickCreateBtn) {
        onClickCreateBtn()
      }
    },
    [openEmailDrawer, onClickCreateBtn, emailDrawerId]
  )

  return (
    <div css={styles.footer} className={className}>
      {channelList.map(channel => (
        <ChannelOption
          {...channel}
          onClickCreateBtn={onClickCreateBtn}
          firstStep={firstStep}
          key={channel.channelType}
        />
      ))}
      <ChannelOption
        title={SECONDARY_EMAIL_CHANNEL.title}
        icon={SECONDARY_EMAIL_CHANNEL.icon}
        onSelect={handleOpenSecondaryEmailChannels}
        firstStep={firstStep}
      />
    </div>
  )
}

export default Footer
