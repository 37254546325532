import React, { useCallback } from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { useDrawer } from 'ducks/drawers/hooks'
import { CHANNEL_EMAIL_TYPES } from 'subapps/settings/components/drawers/Channels/Channels.data'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { styles } from './styles'

const ChannelOption = ({
  className,
  firstStep = false,
  onClickCreateBtn,
  channelType = 'imap',
  icon,
  title,
  onSelect,
}) => {
  const isGmailChannel = channelType === 'google'
  const { openDrawer, drawerId } = useDrawer(
    CHANNEL_EMAIL_TYPES[channelType].drawer
  )

  const handleConnectEmailChannel = useCallback(
    () => {
      openDrawer('new', {
        query: {
          ...buildDrawerQueryParam(drawerId, 'drawerChannelType', channelType),
        },
        additionalProps: {
          firstStep,
          drawerSource: 'inbox',
        },
      })
      if (onClickCreateBtn) {
        onClickCreateBtn()
      }
    },
    [openDrawer, drawerId, onClickCreateBtn, firstStep, channelType]
  )

  return (
    <Button
      css={[styles.btn, isGmailChannel ? styles.oAuthButtton : styles.emailBtn]}
      className={className}
      type={isGmailChannel ? 'info' : 'tertiary'}
      size="xBig"
      onClick={onSelect || handleConnectEmailChannel}
    >
      <span className="channel-icon-wrapper">{icon}</span>
      <span className="grui flex-grow">{title}</span>
    </Button>
  )
}

export default ChannelOption
