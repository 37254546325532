import React from 'react'
import { useSelector } from 'react-redux'

import { selectCustomerIdByIntegrationIdAndEmail } from 'ducks/integrations/shopify/selectors'

import NoCustomer from './NoCustomer'
import DetailsContainer from './DetailsContainer'

const ShopifyIndex = React.memo(props => {
  const { contact, integration } = props

  const { id: customerGid } = useSelector(state =>
    selectCustomerIdByIntegrationIdAndEmail(
      state,
      integration.id,
      contact.email
    )
  )

  return !customerGid ? (
    <div>
      <NoCustomer />
    </div>
  ) : (
    <DetailsContainer {...props} customerGid={customerGid} />
  )
})

ShopifyIndex.displayName = 'ShopifyIndex'

export default ShopifyIndex
