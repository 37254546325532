import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doOpenNotePage, doOpenTicketPage } from 'actions/pages'
import ListenToKeyboard from 'components/ListenToKeyboard'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import { useAiDraftGenerationAction } from 'ducks/ai/hooks'

const ListenToKeyboardContainer = props => {
  const { ticketId } = props
  const dispatch = useDispatch()
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )

  const {
    onAiDraftGenerationClick,
    isAiDraftGenerationFeatureEnabled,
    aiDraftWalletsIsLoaded,
  } = useAiDraftGenerationAction()

  // Access state using useSelector
  const disableForInput = true // As per your existing setup
  const preventDefault = true // As per your existing setup

  // Replace the perform functions with hooks
  const onR = useCallback(
    () => {
      if (!isAdminOrOwnerOrAgent) return
      dispatch(
        doOpenTicketPage(ticketId, { viaKeyboard: true, replying: true })
      )
    },
    [dispatch, ticketId, isAdminOrOwnerOrAgent]
  )

  const onN = useCallback(
    () => {
      dispatch(doOpenNotePage(ticketId))
    },
    [dispatch, ticketId]
  )

  const onF = useCallback(
    () => {
      if (!isAdminOrOwnerOrAgent) return
      dispatch(doOpenTicketPage(ticketId, { replying: true, forwarding: true }))
    },
    [dispatch, ticketId, isAdminOrOwnerOrAgent]
  )

  const onD = useCallback(
    () => {
      if (isAiDraftGenerationFeatureEnabled && aiDraftWalletsIsLoaded) {
        onAiDraftGenerationClick()
      }
    },
    [
      onAiDraftGenerationClick,
      isAiDraftGenerationFeatureEnabled,
      aiDraftWalletsIsLoaded,
    ]
  )

  // Pass down all required props to the ListenToKeyboard component
  return (
    <ListenToKeyboard
      onR={onR}
      onN={onN}
      onF={onF}
      onD={onD}
      disableForInput={disableForInput}
      preventDefault={preventDefault}
      {...props}
    />
  )
}

export default ListenToKeyboardContainer
