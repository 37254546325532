import { css } from '@emotion/react'

const billingPlanLimitedAlert = theme => css`
  min-height: ${theme.spacing[16]};
  background: ${theme.color.primary.negativeAlt};
  button {
    font-weight: ${theme.fontWeight.semibold};
  }
`

const billingPlanLimitedOverdueAlert = css`
  background: #d60000;
`

export const styles = {
  billingPlanLimitedAlert,
  billingPlanLimitedOverdueAlert,
}
