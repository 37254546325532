// Shows Add Contact at the end of this list to start Editing.
// Renders the RecipientSearch (in list mode) after you start Editing.
import React from 'react'

import RecipientSearch from './RecipientSearch'
import { useRecipientsEditor } from './useRecipientsEditor'

const RecipientsEditor = ({
  placeholder,
  recipientsOpenUpward,
  saveOnTab,
  ticketId,
  type,
  tabIndex = '0',
  allowCreateNewContactByName = false,
  allowMultipleRecipients,
  draftType,
}) => {
  const {
    forNote,
    onEscape,
    filtered,
    isSearching,
    currentRecipientSyncSource,
    recipients,
    onAdd,
    onBulkAdd,
    onRemove,
    searchRecipients,
    onFocus,
    onBlur,
    query,
    editing,
    searchBoxRef,
  } = useRecipientsEditor({
    type,
    ticketId,
    allowMultipleRecipients,
    draftType,
  })
  return (
    <RecipientSearch
      forNote={forNote}
      onEscape={onEscape}
      filtered={filtered}
      isSearching={isSearching}
      currentRecipientSyncSource={currentRecipientSyncSource}
      searchRecipients={searchRecipients}
      allowMultipleRecipients={allowMultipleRecipients}
      editing={editing}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      recipients={recipients}
      saveOnTab={saveOnTab}
      ticketId={ticketId}
      type={type}
      upward={recipientsOpenUpward}
      tabIndex={tabIndex}
      onAdd={onAdd}
      onBulkAdd={onBulkAdd}
      onRemove={onRemove}
      allowCreateNewContactByName={allowCreateNewContactByName}
      query={query}
      ref={searchBoxRef}
    />
  )
}

export default RecipientsEditor
