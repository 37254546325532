import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import TrashIcon from '@groovehq/internal-design-system/lib/assets/icons/Trash'

// global
import { Flex } from 'shared/ui'
import { runOnNextTick } from 'util/functions'
import { isCmdEnter } from 'util/keys'
import { ENTER, TAB } from 'constants/keys'
import { useEditorInstance } from 'ducks/editor/hooks'

// re-used
import EmojiPicker from 'components/App/DesktopView/shared/EmojiPicker'
import AiButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/AiButton'
import AssignmentBadge from 'components/App/DesktopView/shared/AssignmentDropdown/Badge'
import ReplyStateIndicator from 'components/ReplyStateIndicator'
import Attachments from 'components/Attachments'
import EditorDropzoneIndicator from 'components/App/DesktopView/shared/Composer/shared/EditorDropzoneIndicator'
import withDropzone from 'components/Dropzone'

// styles
import styles from 'components/App/DesktopView/CommunicationForms/Reply/shared/styles.less'

// local
import SendButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/SendButton'
import AssignmentDropdown from 'components/App/DesktopView/CommunicationForms/Reply/shared/AssignmentDropdown'
import MentionSelector from 'components/MentionSelector'
import AttachmentUpload from 'components/AttachmentUpload'

// NOTE (jscheel): We are temporarily removing follow, but it may come back if
// people complain. Keeping all the code in place, in case we have to bring it back quickly.
// import FollowCheckbox from 'subapps/ticketing/components/CommunicationForms/Note/FollowCheckbox'
// END NOTE

import AttachmentButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/AttachmentButton'
import Editor from './EditorView'
import Sizer from '../../shared/Sizer'
import NoteReplySwitcher from '../shared/NoteReplySwitcher'
import PinButton from '../shared/PinButton'

const FlexWithDropzone = withDropzone(Flex, {
  indicatorComponent: EditorDropzoneIndicator,
  className: cn(styles.EditorDropzone, 'Dropzone'),
})

const FooterWithDropzone = withDropzone('div', {
  skipIndicator: true,
})

class NoteForm extends React.PureComponent {
  static propTypes = {
    draftId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.editorScrollElement = createRef()
    this.footerRef = React.createRef()
    this.aiOverlayTopRef = React.createRef()
    this.aiOverlayWidthRef = React.createRef()
    this.aiOverlayBottomRef = React.createRef()
  }

  state = {
    expandedVisible: this.props.initialExpandedVisible || false,
    ticketFollow: this.props.isTicketFollowing || false,
  }

  // We need to do this, because we cannot hide the editor until changesets are fully loaded, so it is possible to start a draft _without_ defaults. Which is why we need to detect the moment the defaults are available and use doEnsureDefaults to safely set them.
  componentDidUpdate() {
    const {
      defaultsAvailable,
      draftId,
      defaultsSet,
      doEnsureDefaults,
    } = this.props
    if (draftId && !defaultsSet && defaultsAvailable) {
      runOnNextTick(() => doEnsureDefaults(draftId))
    }
  }

  onEditorContentChange = text => {
    const { debouncedHandleDraftChange, draftId, ticketId } = this.props

    if (!draftId && !text) return

    debouncedHandleDraftChange(ticketId, 'body', text, {
      draftId,
    })
  }

  onAgentChange = (_, groupId, agentId) => {
    const { handleDraftChange, draftId, ticketId } = this.props
    handleDraftChange(ticketId, 'assigneeId', agentId, {
      draftId,
    })
  }

  onGroupChange = (_, groupId) => {
    const { handleDraftChange, draftId, ticketId } = this.props
    handleDraftChange(ticketId, 'assigneeGroupId', groupId, {
      draftId,
    })
  }

  onAttachmentClick = () => {
    const { handleDraftChange, draftId, ticketId } = this.props

    handleDraftChange(ticketId, 'touched', true, {
      draftId,
    })
  }

  onSend = () => {
    const { sendDraft, draftId } = this.props
    sendDraft(draftId, this.state.ticketFollow)
  }

  onSendAndOpen = () => {
    const { handleDraftChange, sendDraft, draftId, ticketId } = this.props

    handleDraftChange(ticketId, 'state', 'opened', { draftId })
    sendDraft(draftId, this.state.ticketFollow)
  }

  onSendAndClose = () => {
    const { handleDraftChange, sendDraft, draftId, ticketId } = this.props

    handleDraftChange(ticketId, 'state', 'closed', { draftId })
    sendDraft(draftId, this.state.ticketFollow)
  }

  onSendAndSnooze = snoozeUntil => {
    const {
      handleDraftMultiChange,
      sendDraft,
      draftId,
      ticketId,
      currentMailboxId: mailboxId,
    } = this.props

    handleDraftMultiChange(ticketId, { snoozeUntil, mailboxId }, { draftId })
    sendDraft(draftId, this.state.ticketFollow)
  }

  onDeleteClick = () => {
    const { deleteDraft, ticketId, draftId } = this.props
    deleteDraft(ticketId, draftId)
  }

  afterAttachmentDelete = () => {
    const { handleDraftChange, draftId, ticketId } = this.props

    handleDraftChange(ticketId, 'touched', true, {
      draftId,
    })
  }

  handleDropOnEditor = accepted => {
    const { uploadFiles, draftId } = this.props
    if (!draftId) this.onAttachmentClick()
    runOnNextTick(() => {
      const updatedDraftId = this.props.draftId
      uploadFiles(
        accepted,
        false, // forNote,
        updatedDraftId
      )
    })
  }

  handleDropOnFooter = accepted => {
    const { uploadFiles, draftId } = this.props
    if (!draftId) this.onAttachmentClick()
    setTimeout(() => {
      const updatedDraftId = this.props.draftId
      uploadFiles(
        accepted,
        false, // forNote,
        updatedDraftId
      )
    }, 100)
  }

  handleFollowchange = follow => {
    this.setState({ ticketFollow: follow })
  }

  startTyping = () => {
    const {
      ticketId,
      doRealtimeAgentStartTicketTypingNotification,
    } = this.props
    doRealtimeAgentStartTicketTypingNotification(ticketId)
  }

  handleOnKeyDownEvent = e => {
    // do not re-do the whole mention UI, we're going to dispatch a keydown event
    // so that the Mentions dropdown will capture it and behave like it did before
    // it's needed because tinymce events happen within an iframe
    const event = document.createEvent('Event')
    const type = e.type

    // can bubble, and is cancellable
    event.initEvent(`editor-${type}`, true, true)
    event.forwarded = e
    document.dispatchEvent(event)

    if ((e.keyCode === TAB || e.keyCode === ENTER) && this.state.word) {
      const {
        doMentionAgent,
        selectedMentionSelectorAgentId,
        suppressMentionSelector,
        editorInstanceData: { editorInstance },
      } = this.props

      if (!suppressMentionSelector) {
        doMentionAgent(selectedMentionSelectorAgentId, editorInstance)
        e.preventDefault()
        e.stopPropagation()
      }
    }
    if (isCmdEnter(e)) {
      e.preventDefault()
      e.stopPropagation()
      return this.onSend()
    }
    this.startTyping()

    return true
  }

  handleInput = mention => {
    const { doUpdateAgentMention } = this.props
    const found = mention

    if (!found) {
      this.setState({ word: null })
      return this.closeMentionModalUnlessClosed()
    }

    const word = found.word
    this.setState({ word: found })

    if (word.charAt(0) === '@') return doUpdateAgentMention(found)

    return this.closeMentionModalUnlessClosed()
  }

  closeMentionModalUnlessClosed = () => {
    if (!this.props.isMentioning) return
    this.props.doUpdateAgentMention(null)
  }

  render() {
    const {
      ticketId,
      draftId,
      assignmentLabel,
      assigneeGroupId,
      attachments,
      isSendable,
      isOpen,
      onEditorFormFocus,
      onEditorFormBlur,
      onEditorInit,
      isCurrentUserViewer,
      prefersAiEnabled,
      editorInstanceData: { editorWrapperRef: composerRef, editorInstance },
    } = this.props
    return (
      <div
        id="composer"
        className={styles.form}
        data-test-id="reply-editor-note"
        ref={composerRef}
      >
        <Sizer
          className="note"
          footerRef={this.footerRef}
          ref={this.editorScrollElement}
        >
          <div className="form-header-wrapper">
            <div className="form-header" ref={this.aiOverlayTopRef}>
              <PinButton />
            </div>
          </div>
          <div className="form-editor-wrapper" ref={this.aiOverlayWidthRef}>
            <FlexWithDropzone
              className={cn('editorAndAttachments grui flex-2-1-auto flex-col')}
              onDropCustom={this.handleDropOnEditor}
            >
              <MentionSelector
                legacy={false}
                scrollParentRef={this.editorScrollElement}
              />
              <Editor
                className={'toolbar-bottom'}
                ticketId={ticketId}
                draftId={draftId}
                onChange={this.onEditorContentChange}
                onKeyDown={this.handleOnKeyDownEvent}
                onMention={this.handleInput}
                onFocus={onEditorFormFocus}
                onBlur={onEditorFormBlur}
                onInit={onEditorInit}
              />
              <div className="attachments">
                <Attachments
                  forDraft
                  draftId={draftId}
                  ticketId={ticketId}
                  afterDelete={this.afterAttachmentDelete}
                  footerRef={this.footerRef}
                />
              </div>
            </FlexWithDropzone>
          </div>
          <div className={cn('form-footer-wrapper')} ref={this.footerRef}>
            <FooterWithDropzone
              className="form-footer"
              onDropCustom={this.handleDropOnFooter}
            >
              <div
                className="form-footer-content"
                ref={this.aiOverlayBottomRef}
              >
                <div className="sendButtonContainer">
                  <SendButton
                    className="note-button"
                    actionLabel="Add note"
                    disabled={!isSendable}
                    isOpen={isOpen}
                    ticketId={ticketId}
                    draftId={draftId}
                    isNote
                    onSendClick={this.onSend}
                    onSendOpenClick={this.onSendAndOpen}
                    onSendClosedClick={this.onSendAndClose}
                    onSnoozeOptionClick={this.onSendAndSnooze}
                    dropdownVisible={!isCurrentUserViewer}
                  />
                  <ReplyStateIndicator
                    className={'collisionIndicator'}
                    hideDraftIndicator
                    conversationId={ticketId}
                  />
                </div>
                {prefersAiEnabled && <AiButton />}
                <div className="grui flex flex-grow">
                  <div className="left-side">
                    <div className="actions">
                      <div className="attachments">
                        <AttachmentButton
                          triggerId="attachmentUploadTrigger"
                          attachments={attachments}
                          onClick={this.onAttachmentClick}
                        >
                          <AttachmentUpload
                            forNote
                            hideIcon
                            triggerId="attachmentUploadTrigger"
                            draftId={draftId}
                          />
                        </AttachmentButton>
                      </div>
                      <EmojiPicker editor={editorInstance} direction="right" />
                      <NoteReplySwitcher ticketId={ticketId} />
                      {/* <FollowCheckbox
                        onFollowChange={this.handleFollowchange}
                        isFollowing={this.state.ticketFollow}
                      /> */}
                      {!isCurrentUserViewer && (
                        <AssignmentDropdown
                          direction="left"
                          drilledDownGroupId={assigneeGroupId}
                          key="assignment"
                          onGroupSelect={this.onGroupChange}
                          onAgentSelect={this.onAgentChange}
                          trigger={
                            <div className="left-divider">
                              <AssignmentBadge
                                assignmentLabel={assignmentLabel}
                                size="small"
                                iconSize={24}
                                tooltipPosition="top"
                              />
                            </div>
                          }
                          upward
                        />
                      )}
                    </div>
                  </div>
                  <div className="right-side">
                    <div className="actions">
                      <Tooltip
                        title="Delete note"
                        position="top"
                        strategy="fixed"
                      >
                        <Button
                          type="icon"
                          size="small"
                          onClick={this.onDeleteClick}
                          className="deleteButton"
                          data-test-id="DraftDeleteButton"
                        >
                          <TrashIcon />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </FooterWithDropzone>
          </div>
        </Sizer>
      </div>
    )
  }
}

const withHookHOC = Component => {
  return props => {
    const editorInstanceData = useEditorInstance()

    return <Component editorInstanceData={editorInstanceData} {...props} />
  }
}

export default withHookHOC(NoteForm)
