import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { starsCircle as StarsCircle } from 'assets/icons/groove/v2'
import SuggestedTags from '../SuggestedTags'
import Summary from '../Summary'
import Sentiment from '../Sentiment'

import { styles as baseStyles } from '../styles'
import RegenerateButton from '../RegenerateButton'

const EmptyOrOverview = ({
  ticketId,
  limitReached,
  canShowEmptyState,
  isCurrentUserManager,
  onUpgradeAction,
  isAccessRequestBtnDisabled,
  onGenerateFullOverviewClickSkipLoaded,
  onGenerateFullOverview,
  canShowLoadingState,
  sentimentSuggestion,
  summarySuggestion,
  tagsSuggestion,
  currentPlanName,
  canUseClassifications,
}) => {
  if (limitReached && canShowEmptyState) {
    return (
      <div className="grui text-center">
        <StarsCircle width="64" height="64" className="grui mb-5" />
        <div className="grui mb-6 opacity-70">
          {isCurrentUserManager
            ? `Uh oh, you've used up all of your AI overviews. Upgrade your plan to get unlimited overviews every month!`
            : `Uh oh! You've hit the cap for AI overview generations on the ${currentPlanName} plan. To unlock this feature, an admin of your account will need to upgrade your plan.`}
        </div>
        <Button
          className="grui w-100"
          type="infoOutline"
          size="small"
          onClick={onUpgradeAction}
          css={baseStyles.actionButton}
          disabled={isAccessRequestBtnDisabled}
        >
          {isCurrentUserManager ? 'Upgrade now' : 'Ask an admin to upgrade'}
        </Button>
      </div>
    )
  }

  if (canShowEmptyState) {
    return (
      <div className="grui text-center">
        <StarsCircle width="64" height="64" className="grui mb-5" />
        <div className="grui mb-6 opacity-70">
          {`Summarize the conversation, get tag suggestions, and detect your
          customer's sentiment.`}
        </div>
        <Button
          className="grui w-100"
          type="infoOutline"
          size="small"
          onClick={onGenerateFullOverviewClickSkipLoaded}
          css={baseStyles.actionButton}
        >
          Generate AI overview
        </Button>
      </div>
    )
  }

  return (
    <>
      {canUseClassifications && (
        <Sentiment
          ticketId={ticketId}
          suggestion={sentimentSuggestion.suggestion}
          isLoading={canShowLoadingState}
          className="grui mb-10"
        />
      )}
      <Summary
        key={ticketId}
        suggestion={summarySuggestion.suggestion}
        isLoading={canShowLoadingState}
      />
      {canUseClassifications && (
        <SuggestedTags
          ticketId={ticketId}
          suggestion={tagsSuggestion.suggestion}
          isLoading={canShowLoadingState}
          isLoadingTagNames={tagsSuggestion.isLoadingTagNames}
          isLoadedTagNames={tagsSuggestion.isLoadedTagNames}
        />
      )}
      <RegenerateButton
        isLoading={canShowLoadingState}
        limitReached={limitReached}
        onGenerateFullOverview={onGenerateFullOverview}
      />
    </>
  )
}

export default EmptyOrOverview
