import { useMemo } from 'react'
import { sortableHandle } from 'react-sortable-hoc'

import * as icons from 'assets/icons/groove/v2'
import { KeyValue } from 'components/KeyValues'
import {
  DATE,
  DROPDOWN,
  DURATION,
  IP_LOCATION,
  SINGLE_LINE,
  LINK,
  NUMBER,
  SUM,
  TIMEZONE,
  TYPE_ICONS_MAP,
  URL,
  BOOLEAN,
} from 'ducks/crm/customFields'
import { camelize } from 'util/strings'
import { Icon } from 'util/ui'
import { all } from 'util/arrays'

import withSidebarModalPositioning from '../../withSidebarModalPositioning'
import CustomFieldCopyButton from './CustomFieldCopyButton'
import CustomFieldEditModal from './CustomFieldEditModal'
import CustomFieldValue from './CustomFieldValue'
import useEditableCustomFieldKeyValue from './useEditableCustomFieldKeyValue'

const SINGLE_LINES = [
  DATE,
  DROPDOWN,
  DURATION,
  IP_LOCATION,
  SINGLE_LINE,
  LINK,
  NUMBER,
  SUM,
  TIMEZONE,
  URL,
  BOOLEAN,
]

export default withSidebarModalPositioning(CustomFieldKeyValue)

export const DragHandle = sortableHandle(() => {
  const { draggableDots: Dots } = icons
  return (
    <div className="action dragHandle editAction">
      <Dots />
    </div>
  )
})

function CustomFieldKeyValue({
  fieldKey,
  fieldHidden,
  icon: fieldIcon,
  index,
  isArray = false,
  isEditable: isEditableProp = true,
  isInEditMode,
  offset,
  options,
  onEye,
  menuRef,
  subjectId,
  title,
  type = SINGLE_LINE,
  value,
  values,
  missingValueText = 'Unknown',
}) {
  const {
    isEditable,
    isOpen,
    doClose,
    doOpen,
    doSave,
  } = useEditableCustomFieldKeyValue({
    isEditableProp,
    subjectId,
    type,
  })
  const singlelineField = SINGLE_LINES.includes(type)

  const valueComponent = CustomFieldValue({
    isInEditMode,
    fieldKey,
    options,
    singleline: singlelineField,
    type,
    value,
  })
  const isMissing = useMemo(
    () => {
      return !value || all(eachValue => !eachValue, Object.values(value))
    },
    [value]
  )
  const singleline = singlelineField || isMissing

  const missingValue =
    isEditable && !isInEditMode ? (
      <span className="addMissingValue">Add</span>
    ) : (
      missingValueText
    )
  const iconKey = camelize(fieldIcon)
  const icon = icons[iconKey || TYPE_ICONS_MAP[type] || 'edit']
  const { eye: Eye, eyeSlash: EyeSlash } = icons
  const EyeIcon = fieldHidden ? Eye : EyeSlash
  return (
    <KeyValue
      icon={icon}
      missingValue={missingValue}
      onClick={!isInEditMode ? doOpen : null}
      title={title}
      showActions={isInEditMode === true ? true : null}
      singleline={singleline}
      value={valueComponent}
    >
      {isInEditMode && (
        <>
          <DragHandle />
          {onEye && (
            <div className="action editAction" onClick={onEye}>
              <EyeIcon />
            </div>
          )}
        </>
      )}
      {!isInEditMode && (
        <>
          <CustomFieldCopyButton
            fieldKey={fieldKey}
            type={type}
            value={value}
          />
          {isEditable && (
            <div className="action">
              <Icon name="pencil" size="small" />
            </div>
          )}
          {isEditable &&
            isOpen && (
              <CustomFieldEditModal
                className={type === DATE ? 'date' : ''}
                fieldKey={fieldKey}
                icon={icon}
                index={index}
                isArray={isArray}
                title={title}
                type={type}
                menuRef={menuRef}
                offset={offset}
                options={options}
                onCancel={doClose}
                onSave={doSave}
                value={value}
                values={values}
              />
            )}
        </>
      )}
    </KeyValue>
  )
}
