import React, { useEffect, useState, useMemo } from 'react'
import storage from 'util/storage'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import ProgressRing from '@groovehq/internal-design-system/lib/components/ProgressRing/ProgressRing'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { useDrawer } from 'ducks/drawers/hooks'
import { selectIsInInbox } from 'selectors/location'
import { DRAWER_TYPE_ONBOARDING_WORKFLOW } from 'ducks/drawers/types'
import { selectIsDrawerOpen } from 'ducks/drawers/selectors'
import {
  selectOnboardingWorkflowCompletedAndSkippedList,
  selectFeatureBasedOnboardingWorkflowData,
} from 'subapps/onboarding/selectors'
import { capture } from 'ducks/tracking/actions'
import { doUpdateAccountPreference } from 'ducks/accountPreferences/operations'
import {
  isBoostrappedSelector,
  selectAccountUsageOnboarding,
} from 'selectors/app/base'
import { selectShowOnboardingWorkflowProgressIndicator } from 'subapps/onboarding/selectors/selectShowOnboardingWorkflowProgressIndicator'
import { styles } from './styles'

const ProgressIndicator = ({ className }) => {
  const dispatch = useDispatch()
  const isBoostrapped = useSelector(isBoostrappedSelector)
  const isInInbox = useSelector(selectIsInInbox)
  const usageOnboarding = useSelector(selectAccountUsageOnboarding)
  const onboardingWorkflowData = useSelector(
    selectFeatureBasedOnboardingWorkflowData
  )
  const total = useMemo(() => Object.keys(onboardingWorkflowData).length, [
    onboardingWorkflowData,
  ])
  const showOnboardingWorkflowProgressIndicator = useSelector(
    selectShowOnboardingWorkflowProgressIndicator
  )
  const { openDrawer: openOnboardingWorkflow } = useDrawer({
    type: DRAWER_TYPE_ONBOARDING_WORKFLOW,
  })
  const isDrawerOpen = useSelector(selectIsDrawerOpen)
  const completedCount = useSelector(
    selectOnboardingWorkflowCompletedAndSkippedList
  ).length
  const [completedSending, setCompletedSending] = useState(false)
  const allCompleted = total && total === completedCount

  useEffect(
    () => {
      if (
        showOnboardingWorkflowProgressIndicator &&
        allCompleted &&
        !usageOnboarding?.completed &&
        !completedSending
      ) {
        setCompletedSending(true)
        capture('onboarding state completed')
        dispatch(
          doUpdateAccountPreference(
            'usage_onboarding',
            JSON.stringify({
              ...usageOnboarding,
              completed: true,
            })
          )
        )
      }
    },
    [
      showOnboardingWorkflowProgressIndicator,
      allCompleted,
      dispatch,
      usageOnboarding,
      completedSending,
    ]
  )

  useEffect(
    () => {
      const shownOnboardingWorkflow =
        storage.get('shownOnboardingWorkflow') || 0
      if (Date.now() - shownOnboardingWorkflow < 60 * 60 * 1000) return
      if (!showOnboardingWorkflowProgressIndicator) return
      if (!isInInbox) return
      if (!isBoostrapped) return

      storage.set('shownOnboardingWorkflow', new Date().getTime())
      // In the event the customer is inside of index and they already
      // have a drawer open, then we dont want our auto popup to replace
      // the existing open drawer
      if (!isDrawerOpen) {
        openOnboardingWorkflow()
      }
    },
    [
      isBoostrapped,
      isInInbox,
      openOnboardingWorkflow,
      showOnboardingWorkflowProgressIndicator,
      isDrawerOpen,
    ]
  )

  if (!showOnboardingWorkflowProgressIndicator) {
    return null
  }

  return (
    <button
      className={cn(
        'grui flex items-center justify-center px-8 py-5 w-100',
        { disabled: allCompleted },
        className
      )}
      css={[button.styles.preflight, styles.progressIndicator]}
      onClick={!allCompleted && openOnboardingWorkflow}
    >
      <div className="grui mr-5 text-right">
        <span
          className={cn({ incomplete: !allCompleted })}
          css={styles.progressHeading}
        >
          Quick Start
        </span>
        <br />
        {!allCompleted && <span>{total - completedCount} Still to go</span>}
        {allCompleted && (
          <span>
            <span css={styles.star}>✨{` `}</span>You’re all done!
          </span>
        )}
      </div>
      {!allCompleted && (
        <ProgressRing
          completedCount={completedCount}
          total={total}
          color="color.primary.warningText"
        />
      )}
    </button>
  )
}

export default ProgressIndicator
