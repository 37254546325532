import { css } from '@emotion/react'
import Label from './Label'
import Row from './IndentedRow'
import RecipientsEditor from './RecipientsEditor'

const flexStyle = css`
  flex: 1 1 100%;
`

export default function CcRow({ className, isNote, ...rest }) {
  const { ticketId } = rest
  return (
    <Row css={flexStyle} className={className}>
      <Label>Cc:</Label>
      <RecipientsEditor
        key={`recipientseditor-cc-${ticketId}`}
        className="grui ml-3"
        type="cc"
        allowMultipleRecipients
        ticketId={ticketId}
        draftType={isNote ? 'note' : 'reply'}
        {...rest}
      />
    </Row>
  )
}
