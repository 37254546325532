import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Link from 'redux-first-router-link'
import {
  button,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import NoticeBar from 'components/NoticeBar'
import { timeInHuman } from 'util/date'
import {
  SETTINGS_BILLING_PAGE,
  SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
} from 'subapps/settings/types'
import { selectIsBillingPage } from 'subapps/settings/selectors'
import { DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'

import OAuthEmailChannelDisconnected from './OAuthEmailChannelDisconnected'
import MessengerDisconnected from './MessengerDisconnected'
import ForwardingUnconfirmed from './ForwardingUnconfirmed'
import DemoMailboxBar from './DemoMailboxBar'
import SetupLegacy from './SetupLegacy'
import TrialMessage from './TrialMessage'
import BillingPlanLimited from './BillingPlanLimited'
import styles from './styles.less'
import TwoFactorAuthDisabled from './TwoFactorAuthDisabled'

export default function AppNoticesView({
  showDemoMailboxBar,
  showExpiredBilling,
  showGitHubReauth,
  showPendingCancellation,
  cancelsAt,
  showCreditCardExpireSoon,
  showCreditCardExpired,
  creditCard,
  showLastBillingFailed,
  showTrialMessage,
  trialDaysLeft,
  isManager,
  isCurrentMailboxConfirmedOrUnconfirmed,
  isCurrentMailboxDisconnected,
  isCurrentMailboxSyncing,
  isCurrentMailboxError,
  isWidgetFromUrlDisconnected,
  isCurrentMailboxConfirmedOrUnconfirmedLegacy,
  showTwitterDeprecation,
  isSettingUpTheCurrentMailBox,
  showContinueForwardingSetupPane,
  isSearchesLoaded,
  showContinueForwardingSetupBar,
  showBillingPlanUsageLimited,
  currentMailboxErrorMessage,
  currentUser2faEnforced,
}) {
  const isBillingPage = useSelector(selectIsBillingPage)
  const { openDrawer: openSetupCardDrawer } = useDrawer({
    type: DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD,
  })

  const creditCardBtn = isBillingPage ? (
    <button
      css={[button.styles.preflight, text.styles.fontSemibold]}
      type="button"
      className={styles.buttonLink}
      onClick={openSetupCardDrawer}
    >
      Update credit card
    </button>
  ) : (
    <Link className={styles.buttonLink} to={{ type: SETTINGS_BILLING_PAGE }}>
      Update credit card
    </Link>
  )

  const notices = []
  const creditCardLinkOrHint = isManager
    ? creditCardBtn
    : ' Ask your account owner or admin to update it.'
  // The order of the notice below reflects their importance top -> most important
  if (currentUser2faEnforced) {
    notices.push(<TwoFactorAuthDisabled />)
  }
  if (showLastBillingFailed) {
    notices.push(
      <NoticeBar key="app-notice-billingfailed">
        We are unable to charge the credit card associated with your account.
        {creditCardLinkOrHint}
      </NoticeBar>
    )
  }
  if (showExpiredBilling) {
    notices.push(
      <NoticeBar key="app-notice-expiredbilling" noticeFlagText="HEADS UP!">
        To continue using Groove please update your billing information.
      </NoticeBar>
    )
  }
  if (showPendingCancellation) {
    notices.push(
      <NoticeBar
        key="app-notice-pendingcancellation"
        flagName="app-notice-pendingcancellation"
        dismissible
        forceShow
      >
        Your account is scheduled to close on{' '}
        {timeInHuman(cancelsAt, { yearFormat: '%y' })}
      </NoticeBar>
    )
  }
  if (showCreditCardExpired) {
    notices.push(
      <NoticeBar key="app-notice-cardexpired">
        The credit card associated to your account has expired.
        {creditCardLinkOrHint}
      </NoticeBar>
    )
  }
  if (showCreditCardExpireSoon) {
    const { expiryMonth, expiryYear } = creditCard
    const expireDate = moment(`${expiryYear}-${expiryMonth}-01`, 'YYYY-MM-DD')
    const flagName = `has_seen_credit_card_expire_soon_${expiryYear}_${expiryMonth}`
    notices.push(
      <NoticeBar
        key="app-notice-cardexpirysoon"
        flagName={flagName}
        forceShow
        dismissible
      >
        The credit card associated to your account will be expiring at the end
        of {expireDate.format('MMM YYYY')}.
        {creditCardLinkOrHint}
      </NoticeBar>
    )
  }

  if (isCurrentMailboxError) {
    notices.push(
      <NoticeBar key="app-notice-mailbox-error" type="error">
        <p className={styles.noticeBarTitle}>
          Your mailbox is returning an error when we try to send emails:
        </p>
        <p>{currentMailboxErrorMessage}</p>
        <p>
          Take a look at our{' '}
          <a
            href="https://help.groovehq.com/help/fixing-smtp-errors"
            target="_blank"
            rel="noopener noreferrer"
          >
            help article
          </a>{' '}
          for more information on how to resolve this issue.
        </p>
      </NoticeBar>
    )
  }

  if (isCurrentMailboxSyncing) {
    notices.push(
      <NoticeBar key="app-notice-mailbox-syncing" type="info">
        This mailbox is currently syncing. It may take a few minutes to
        complete.
      </NoticeBar>
    )
  }

  if (showBillingPlanUsageLimited) {
    notices.push(<BillingPlanLimited />)
  }

  if (showDemoMailboxBar) {
    notices.push(
      <DemoMailboxBar key="app-notice-demomailbox" isManager={isManager} />
    )
  }

  if (
    isCurrentMailboxConfirmedOrUnconfirmed &&
    !isSettingUpTheCurrentMailBox &&
    !showContinueForwardingSetupPane &&
    !showContinueForwardingSetupBar &&
    isSearchesLoaded // prevent blip of this showing before showContinueForwardingSetupPane has ticket counts data
  ) {
    notices.push(
      <ForwardingUnconfirmed
        key="app-notice-mailbox-confirmed-unconfirmed"
        isManager={isManager}
      />
    )
  }

  if (
    isCurrentMailboxConfirmedOrUnconfirmedLegacy &&
    !isSettingUpTheCurrentMailBox
  ) {
    notices.push(
      <SetupLegacy
        key="app-notice-legacy-mailbox-confirmed-unconfirmed"
        isManager={isManager}
      />
    )
  }

  if (showTrialMessage) {
    notices.push(
      <TrialMessage trialDaysLeft={trialDaysLeft} isManager={isManager} />
    )
  }
  if (isCurrentMailboxDisconnected) {
    // KevinR: 2022-01-05
    // I know this breaks the convention of this file. The reason for this change
    // Is that this notice needs to open up a drawer using useDrawer. Hooks cannot
    // be conditionally called inside if statements, but by moving ths hook and the
    // code into a seperate component, I can effectively make the hook get called
    // conditionally within the "rules" of react
    notices.push(
      <OAuthEmailChannelDisconnected
        key="app-notice-mailbox-disconnected"
        isManager={isManager}
      />
    )
  }

  if (isWidgetFromUrlDisconnected > 0) {
    notices.push(
      <MessengerDisconnected
        key="app-notice-messenger-disconnect"
        isManager={isManager}
      />
    )
  }

  if (showGitHubReauth) {
    notices.push(
      <NoticeBar key="app-notice-githubReauth">
        The GitHub integration has been updated and needs to be re-installed.
        Read more about it in our{' '}
        <Link
          className={styles.demoBarLinkInline}
          target="_blank"
          to="https://help.groovehq.com/help/github-integration#existing-users"
        >
          KB article
        </Link>{' '}
        or go to the{' '}
        <Link
          className={styles.demoBarLinkInline}
          to={{
            type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
            payload: { provider: 'github' },
          }}
        >
          integration settings
        </Link>{' '}
        to continue.
      </NoticeBar>
    )
  }

  if (showTwitterDeprecation) {
    notices.push(
      <NoticeBar key="app-notice-twitter-deprecation" type="warning">
        We have discontinued our Twitter integration due to recent changes to
        their business model. Read more{' '}
        <Link
          target="_blank"
          to="https://help.groovehq.com/help/sunsetting-the-twitter-integration"
        >
          here
        </Link>.
      </NoticeBar>
    )
  }

  if (notices.length === 0) return null
  return notices.length > 0 ? notices[0] : null
}
