import React, { Fragment, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { unixTimestampToMs } from 'util/date'
import { formatDuration } from 'util/numbers'
import {
  defaultCustomFieldsOrder,
  CUSTOMER_MARKETING_OPT_IN_LEVEL,
  PRODUCT_SUBSCRIBER_STATUS,
  MISSING_VALUE,
} from 'ducks/integrations/shopify/constants'
import { shopifyMoneyString } from 'util/currency'
import { capture } from 'ducks/tracking/actions'
import { selectCustomFieldCategoryForKey } from 'ducks/crm/customFieldCategories/selectors'
import { selectCurrentContactId } from 'ducks/crm/contacts'

import ReorderableAndHideableComponents from 'components/ReorderableAndHideableComponents'
import IntegrationCustomField from 'components/crm/ContactDetailsColumn/WidgetCards/cards/CustomFieldCategory/IntegrationCustomField'
import RecentOrders from './RecentOrders'

const CustomerDetails = React.memo(props => {
  const {
    totalSpent,
    customerGid,
    lifetimeDuration,
    marketingOptInLevel,
    productSubscriberStatus,
    taxExemptions,
    integration,
  } = props

  const lifetimeDurationString =
    lifetimeDuration &&
    formatDuration(new Date().getTime() - unixTimestampToMs(lifetimeDuration))
  const totalSpentString = shopifyMoneyString(totalSpent || {})

  const keyPrefix = `shopify_${integration.storeDomain}.myshopify.com`
  const customFieldCategory = useSelector(state =>
    selectCustomFieldCategoryForKey(state, {
      key: keyPrefix,
    })
  )
  const contactId = useSelector(state => selectCurrentContactId(state))
  const initialOrder = defaultCustomFieldsOrder(keyPrefix)

  const handleOnShowToggle = useCallback(shown => {
    if (shown) {
      capture('Shopify Customer Expanded')
    } else {
      capture('Shopify Customer Collapsed')
    }
  }, [])

  const componentOverides = useMemo(
    () => ({
      [`${keyPrefix}_lifetime_duration`]: {
        type: 'SINGLE_LINE',
        value: {
          content: lifetimeDurationString,
        },
      },
      [`${keyPrefix}_total_spent`]: {
        type: 'SINGLE_LINE',
        value: {
          content: totalSpentString,
        },
      },
      [`${keyPrefix}_marketing_opt_in_level`]: {
        type: 'SINGLE_LINE',
        value: {
          content: CUSTOMER_MARKETING_OPT_IN_LEVEL[marketingOptInLevel],
        },
      },
      [`${keyPrefix}_product_subscriber_status`]: {
        type: 'SINGLE_LINE',
        value: {
          content: PRODUCT_SUBSCRIBER_STATUS[productSubscriberStatus],
        },
      },
      [`${keyPrefix}_tax_exemptions`]: {
        type: 'SINGLE_LINE',
        value: {
          content: taxExemptions || '',
        },
      },
    }),
    [
      keyPrefix,
      lifetimeDurationString,
      totalSpentString,
      marketingOptInLevel,
      taxExemptions,
      productSubscriberStatus,
    ]
  )

  return (
    <Fragment>
      <div className="grui pt-7 pl-7 pr-7 pb-4">
        <ReorderableAndHideableComponents
          className="SortableCustomFieldsContainer"
          collection={`category:${customFieldCategory?.id}`}
          initialOrder={initialOrder}
          // eslint-disable-next-line react/jsx-no-bind
          itemComponent={p => (
            <IntegrationCustomField
              {...p}
              componentOverides={componentOverides}
              missingValueText={MISSING_VALUE}
            />
          )}
          itemProps={{
            categoryType: 'CONTACT',
            subjectId: contactId,
          }}
          itemNoun="field"
          namespace="contact_details_column"
          onToggle={handleOnShowToggle}
        />
      </div>
      <RecentOrders customerId={customerGid} integrationId={integration.id} />
    </Fragment>
  )
})

CustomerDetails.displayName = 'CustomerDetails'

export default CustomerDetails
