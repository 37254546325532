import React, { useCallback, useContext } from 'react'

import NavigatableIntegrationWidgetCard, {
  NavigatableIntegrationWidgetCardContext,
} from 'components/integrations/NavigatableIntegrationWidgetCard'
import { capitalize, humanize } from 'util/strings'
import {
  useStatus,
  useTitle,
  useBillingCycle,
} from 'ducks/integrations/stripe/hooks/subscriptions'

export default function ListItem({ subscription }) {
  const { id } = subscription || {}
  const { navigatableSetState } = useContext(
    NavigatableIntegrationWidgetCardContext
  )

  const title = useTitle(subscription)
  const subTitle = useBillingCycle(subscription)
  const status = useStatus(subscription)

  const onClick = useCallback(
    () => {
      navigatableSetState({ id, type: 'subscription' })
    },
    [navigatableSetState, id]
  )

  return (
    <NavigatableIntegrationWidgetCard.ListItem
      onClick={onClick}
      subTitle={subTitle}
      subTitleStatus={status}
      subTitleStatusText={capitalize(humanize(subscription.status))}
      title={title}
    />
  )
}
