import React, { useMemo } from 'react'
import { useCustomer } from 'ducks/integrations/stripe/hooks/customers'
import { Loader } from 'shared/ui'

import LinkedResourceActions from 'components/integrations/LinkedResourceActions'
import NavigatableIntegrationWidgetCard from 'components/integrations/NavigatableIntegrationWidgetCard'
import EmptyMessage from 'components/App/DesktopView/Layout/RightSidebar/InAppCards/shared/EmptyMessage'
import Subscriptions from './Subscriptions/List'
import Charges from './Charges/List'

export default function StripeIndex({ integration }) {
  const customer = useCustomer(integration.id)

  const actions = useMemo(
    () => [
      {
        blankTarget: true,
        text: ['View in Stripe'],
        url: `https://dashboard.stripe.com/customers/${customer.id}`,
      },
    ],
    [customer]
  )

  if (customer.isLoading) {
    return (
      <div className="grui p-7">
        <Loader />
      </div>
    )
  }

  if (!customer.id) {
    return (
      <div className="grui p-7">
        <EmptyMessage>There is no customer information</EmptyMessage>
      </div>
    )
  }

  return (
    <div>
      <Subscriptions integrationId={integration.id} customerId={customer.id} />
      <Charges integrationId={integration.id} customerId={customer.id} />

      <NavigatableIntegrationWidgetCard.Footer>
        <LinkedResourceActions
          actions={actions}
          className="grui m-0 w-100 flex justify-end"
        />
      </NavigatableIntegrationWidgetCard.Footer>
    </div>
  )
}
