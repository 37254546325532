import React from 'react'
import { useSelector } from 'react-redux'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import StartContent from 'components/StartContent'
import inbox from 'assets/inbox_empty.png'
import Footer from './Footer'
import { styles } from './styles'

const EmptyPage = ({ className }) => {
  const currentUser = useSelector(selectCurrentUser)

  const firstName = currentUser?.first_name

  return (
    <div css={styles.container} className={className}>
      <div css={styles.innerContainer} className="empty-inbox-content">
        <StartContent
          title={firstName ? `Welcome ${firstName}!` : 'Welcome!'}
          subtitle={`Let's get your emails flowing in by connecting your first ${app.t(
            'mailbox'
          )} to your Groove account.`}
          css={styles.content}
        >
          <div css={[text.styles.textSm, text.styles.fontSemibold]}>
            Setup time of 2 minutes or less
          </div>
          <Footer firstStep />
        </StartContent>
        <div css={styles.imgWrapper} className="empty-inbox-image-wrapper">
          <img src={inbox} alt="Empty" />
        </div>
      </div>
    </div>
  )
}

export default EmptyPage
