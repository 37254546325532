import { connect } from 'react-redux'
import {
  selectShowDemoMailboxBar,
  selectIsSettingUpTheCurrentMailBox,
  selectCurrentMailboxErrorMessage,
} from 'selectors/mailboxes'
import { selectDoesCurrentMailboxHaveState } from 'selectors/mailboxes/selectDoesCurrentMailboxHaveState'
import {
  selectShowCreditCardExpireSoon,
  selectShowCreditCardExpired,
  selectPrimaryCreditCard,
  selectShowLastBillingFailed,
} from 'ducks/billing/selectors'
import { selectCancelsAt } from 'ducks/billing/selectors/selectCancelsAt'
import { selectShowPendingCancellation } from 'ducks/billing/selectors/selectShowPendingCancellation'
import { selectShowExpiredBilling } from 'ducks/billing/selectors/selectShowExpiredBilling'
import { selectShowTrialMessage } from 'ducks/billing/selectors/selectShowTrialMessage'
import { selectDaysLeftBeforeExpiring } from 'ducks/billing/selectors/selectDaysLeftBeforeExpiring'
import { selectShowBillingPlanUsageLimited } from 'ducks/billing/selectors/usage/selectShowBillingPlanUsageLimited'
import {
  selectCurrentUser2faEnforced,
  selectCurrentUserIsAdminOrOwner,
} from 'ducks/currentUser/selectors'
import { selectGitHubNeedsReinstall } from 'ducks/integrations/github'
import { selectIsWidgetFromUrlDisconnected } from 'ducks/widgets/selectors/widgets'
import { selectShowTwitterDeprecation } from 'ducks/integrations/twitter/selectors'
import { selectSearchesRequestState } from 'selectors/search'
import {
  selectShowContinueForwardingSetupBar,
  selectShowContinueForwardingSetupPane,
} from 'selectors/layout'
import AppNoticesView from './view'

const select = state => {
  const {
    loaded: isSearchesLoaded,
    error: isSearchesError,
  } = selectSearchesRequestState(state)

  return {
    showDemoMailboxBar: selectShowDemoMailboxBar(state),
    showExpiredBilling: selectShowExpiredBilling(state),
    showGitHubReauth: selectGitHubNeedsReinstall(state),
    showPendingCancellation: selectShowPendingCancellation(state),
    showCreditCardExpireSoon: selectShowCreditCardExpireSoon(state),
    showCreditCardExpired: selectShowCreditCardExpired(state),
    showLastBillingFailed: selectShowLastBillingFailed(state),
    creditCard: selectPrimaryCreditCard(state),
    cancelsAt: selectCancelsAt(state),
    showTrialMessage: selectShowTrialMessage(state, 'inbox'),
    trialDaysLeft: selectDaysLeftBeforeExpiring(state, 'inbox'),
    isManager: selectCurrentUserIsAdminOrOwner(state),
    isCurrentMailboxConfirmedOrUnconfirmed: selectDoesCurrentMailboxHaveState(
      state,
      ['unconfirmed'],
      null,
      ['FORWARDING']
    ),
    isCurrentMailboxDisconnected:
      selectDoesCurrentMailboxHaveState(state, ['disconnected']) ||
      selectDoesCurrentMailboxHaveState(
        state,
        ['disconnected', 'unconfirmed'],
        null,
        ['IMAP']
      ),
    isCurrentMailboxSyncing: selectDoesCurrentMailboxHaveState(state, [
      'syncing',
    ]),
    isCurrentMailboxError: selectDoesCurrentMailboxHaveState(state, ['error']),
    currentMailboxErrorMessage: selectCurrentMailboxErrorMessage(state),
    isCurrentMailboxConfirmedOrUnconfirmedLegacy: selectDoesCurrentMailboxHaveState(
      state,
      ['unconfirmed'],
      null,
      ['LEGACY']
    ),
    isWidgetFromUrlDisconnected: selectIsWidgetFromUrlDisconnected(state),
    showTwitterDeprecation: selectShowTwitterDeprecation(state),
    isSettingUpTheCurrentMailBox: selectIsSettingUpTheCurrentMailBox(state),
    showContinueForwardingSetupPane: selectShowContinueForwardingSetupPane(
      state
    ),
    showContinueForwardingSetupBar: selectShowContinueForwardingSetupBar(state),
    isSearchesLoaded: isSearchesLoaded && !isSearchesError,
    showBillingPlanUsageLimited: selectShowBillingPlanUsageLimited(state),
    currentUser2faEnforced: selectCurrentUser2faEnforced(state),
  }
}

export default connect(select)(AppNoticesView)
